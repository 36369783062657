import Vue from 'vue';
import {
    Button,
    NavBar,
    Field,
    Toast,
    Swipe,
    SwipeItem,
    Dialog,
    Icon,
    DropdownMenu,
    DropdownItem,
    Popup,
    Picker,
    Tab,
    Tabs,
    Uploader,
    Lazyload,
    Divider,
    SubmitBar,
    AddressList,
    AddressEdit,
    PullRefresh,
    Circle,
    Tabbar,
    TabbarItem,
    Rate,
    NoticeBar,
    Search,
    Pagination,
    DatetimePicker,
    Overlay,
    Cell,
    CellGroup,
    CountDown,
    Checkbox,
    CheckboxGroup,
    ImagePreview,
    Progress,
    Image as VanImage,
    RadioGroup,
    Radio,
    List,
    Area,
    PasswordInput,
    NumberKeyboard,
    Calendar,
    Popover,
    Step,
    Steps,
    Switch
} from 'vant';

Vue.use(Button);
Vue.use(NavBar);
Vue.use(Field);
Vue.use(Toast);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Dialog);
Vue.use(Icon);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Uploader);
Vue.use(Lazyload);
Vue.use(Divider);
Vue.use(SubmitBar);
Vue.use(AddressList);
Vue.use(AddressEdit);
Vue.use(PullRefresh);
Vue.use(Circle);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Rate);
Vue.use(NoticeBar);
Vue.use(Search);
Vue.use(Pagination);
Vue.use(DatetimePicker);
Vue.use(Overlay);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(CountDown);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(ImagePreview);
Vue.use(Progress);
Vue.use(VanImage);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(List);
Vue.use(Area);
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Calendar);
Vue.use(Popover);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Switch);